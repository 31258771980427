import type DataColumn from './DataGrid.DataColumn.ts';

export default class DataColumnGroup {
    groupId: string;
    parentGroupId: string;
    headerName: string;
    uid: string;
    headerTextSlot?: any;
    headerMenuSlot?: any;
    children: Array<DataColumn | DataColumnGroup>;

    get name() {
        return this.groupId;
    }
    get caption() {
        return this.headerName;
    }
    get left() {
        return this.children[0].left;
    }
    set left(value) {
        this.children[0].left = value;
    }

    get width() {
        return this.children.reduce((sum, column) => sum += column.width, 0);
    }
    set width(value) {
        const widthToAdd = (value - this.width) / this.children.length;
        this.children.forEach(col => {
            if (col.width + widthToAdd > col.minWidth) {
                return col.width += widthToAdd;
            }
        });
    }


    get minWidth() {
        return this.children.reduce((sum, col) => sum + col.minWidth, 0);
    }

    get pinned() {
        return this.children[0].pinned;
    }

    get resizable() {
        let isResizable = true;
        this.children.forEach(col => {
            if (!col.resizable) { isResizable = false; }
        });
        return isResizable;
    }

    get suppressMovable() {
        let suppressMovable = false;
        this.children.forEach(col => {
            if (col.suppressMovable) { suppressMovable = true; }
        });
        return suppressMovable;
    }

    get order() {
        return this.children[0].order;
    }

    get shown() {
        return !this.hide;
    }
    set shown(value) {
        this.hide = !value;
    }

    get hide() {
        return this.children.every(item => item.hide);
    }
    set hide(value) {
        this.children.forEach(item => item.hide = value);
    }

    get hideFromChooser(): boolean {
        return this.children.every(item => item.hideFromChooser);
    }

    get oneOrMoreShown() {
        let shown = false;
        this.children.forEach(item => { if (item.shown) { shown = true; } });
        return shown;
    }

    constructor(options) {
        if (!options.children) { throw new Error('DataColumnGroup: children parameter is required'); }
        this.uid = options.uid ?? window.crypto['randomUUID']();

        //--- Groups ---
        this.children = options.children;
        this.groupId = options.groupId;   // Group id linking this group to children
        this.parentGroupId = options.parentGroupId; // Group id linking this group to parent

        //--- Groups Header ---
        this.headerName = options.headerName ?? options.headername;

        this.headerTextSlot = options.headerTextSlot;
        this.headerMenuSlot = options.headerMenuSlot;

    }
}

export type ColumnGroupOptions = {
    groupId: string,
    parentGroupId?: string,
    headerName?: string,
    children?: DataColumn[],
    /** @deprecated */
    headername?: string
}